<template>
    <textarea
      :class="['form-control input-border', customClass]"
      :style="[defaultStyle, customStyle]"
      :value="value"
      @input="onInput"
      @paste="handlePaste"
      @keydown="handleKeydown"
      :rows="rows"
      :placeholder="placeholder"
    ></textarea>
  </template>
  
  <script>
  export default {
    name: 'ZipCodeTextArea',
    props: {
      value: {
        type: String,
        default: ''
      },
      rows: {
        type: Number,
        default: 5
      },
      placeholder: {
        type: String,
        default: ''
      },
      disabled: Boolean,
      customClass: {
        type: [String, Array, Object],
        default: ''
      },
      customStyle: {
        type: [String, Object, Array],
        default: () => ({})
      }
    },
    computed: {
      defaultStyle() {
        return {
          minHeight: '11rem',
          color: '#222a42'
        };
      }
    },
    methods: {
      onInput(e) {
        const onlyDigits = e.target.value.replace(/[^\d\n]/g, '');
        e.target.value = onlyDigits;
        this.$emit('input', onlyDigits);
      },
      handlePaste(event) {
        event.preventDefault();
  
        let pastedText = (event.clipboardData || window.clipboardData).getData('text');
  
        let modifiedText = pastedText
          .replace(/,/g, '\n')
          .replace(/\s+/g, '\n')
          .replace(/[^\d\n]/g, '')
          .replace(/\n{2,}/g, '\n');
  
        const textarea = event.target;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const value = this.value;
  
        const newValue = value.slice(0, start) + modifiedText + value.slice(end);
        this.$emit('input', newValue);
      },
      handleKeydown(event) {
        if (event.key === ' ' || event.key === ',') {
          event.preventDefault();
  
          const textarea = event.target;
          const start = textarea.selectionStart;
          const end = textarea.selectionEnd;
          const value = this.value;
  
          const newValue = value.slice(0, start) + '\n' + value.slice(end);
          const cleanedValue = newValue.replace(/\n{2,}/g, '\n');
  
          this.$emit('input', cleanedValue);
  
          this.$nextTick(() => {
            textarea.selectionStart = textarea.selectionEnd = start + 1;
          });
        }
      }
    }
  };
  </script>
  